import {
  IEService,
  IIEService,
  IUpdateEServiceRequest,
} from '../service/bought-endpoint.service';

export class EService implements IIEService, IUpdateEServiceRequest {
  public isUpdated = false; // must be different name from "updated" in API
  public updated?: Date; // this is the date of the "latest update"

  // Typescript will automatically add and assign the public members...
  constructor(
    public id: number,
    public name: string,
    public displayName: string,
    public description: string,
    public enabledFrom: Date,
    public disabledFrom: Date,
    public readOnly: boolean,
    public isRealEstateService: boolean
  ) {}

  isActive() {
    const now = new Date();
    return (
      this.enabledFrom != null &&
      now > this.enabledFrom &&
      (this.disabledFrom == null || now < this.disabledFrom)
    );
  }
}
