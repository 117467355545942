import { IFeature } from '@data/service/bought-endpoint.service';
import { EService } from './eservice.model';

/*
 * A data model class for Region Owned Service - an EService but with additional attribute(s):
 *       public disabledFrom: Date,
 *       public isPartOfBasicSortiment: boolean,
 *       public notReleased: boolean)
 */
export class RegionOwnedService extends EService {

    // Only a certain set of features are valid for each service
    public validFeatures: IFeature[] = [];

    constructor(
        eService: EService, // use the settings from a template object
        enabledFrom: Date, // a region has its own values in the enabledFrom/disabledFrom fields
        disabledFrom: Date,
        public featureIds: number[]) {
        super(eService.id, eService.name, eService.displayName, eService.description,
            enabledFrom, disabledFrom,
            eService.readOnly, eService.isRealEstateService);
    }
}
