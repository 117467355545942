import { CustomerType } from '@data/service/bought-endpoint.service';
import { CustomerOwnedService } from './customer-owned-service.model';

export class Customer {

    public presentableId: number;

    constructor(
        public id: number,
        public name: string,
        public regionId: number,
        public customerNumber: number,
        public ownedServices: CustomerOwnedService[],
        public showServices: boolean = false,
        public customerType: CustomerType = CustomerType.Unknown
    ) {
        /*
            Check if customer number is set to default value.
            Some customers in bought services (legacy customers) are lacking customer numbers.
            Therefor we can not concat region and customer number as a presentable identifier.
        */
        if(customerNumber === -1) {
            this.presentableId = id;
        }
        else {
            const paddedCustomerNumber = customerNumber.toString().length < 4 ? `0${customerNumber}` : customerNumber;
            this.presentableId = parseInt(`${regionId}${paddedCustomerNumber}`);
        }
    }

    // Update with latest values - only update the fields of interest
    updateOwnedService(updatedService: CustomerOwnedService) {
        for ( const service of this.ownedServices ) {
            if ( updatedService.id === service.id ) {
                service.enabledFrom = updatedService.enabledFrom;
                service.disabledFrom = updatedService.disabledFrom;
                service.updated = new Date();
            }
        }
    }

    getOwnedServicesFilter(filterServiceName: string): CustomerOwnedService[] {
        const allServices = this.ownedServices;
        if (filterServiceName === '') {
            return allServices;
        }
        return allServices.filter(s => s.name.toLowerCase().includes(filterServiceName));
    }

    getDisabledServiceWarning() {
        if (this.ownedServices.findIndex(s => !s.isActive()) !== -1) {
            return '(!)';
        }
        return '';
    }

}
