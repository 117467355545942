import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  ContentLayoutComponent,
  IframeLayoutComponent,
  ErrorComponent,
} from '@hsbweb/hsb-shared-components-module';
import { AuthGuardService } from '@hsbweb/hsb-shared-core-module';

const isIframe = window.self !== window.top;

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: isIframe ? IframeLayoutComponent : ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'boughtservices',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: 'error-page',
    component: isIframe ? IframeLayoutComponent : ContentLayoutComponent,
    children: [
      {
        path: '',
        component: ErrorComponent,
      },
    ],
  },
  {
    path: 'iframe',
    canActivate: [AuthGuardService],
    component: IframeLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'boughtservices',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'boughtservices',
        loadChildren: () =>
          import('./modules/boughtservices/page/bought-services.module').then(
            (m) => m.BoughtServicesModule
          ),
      },
    ],
  },
  // Fallback path
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
