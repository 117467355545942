import { EService } from './eservice.model';
import { IIRealEstateEService } from '@data/service/bought-endpoint.service';

export class RealEstateOwnedService extends EService {
  constructor(
    eService: EService,
    enabledFrom: Date,
    disabledFrom: Date,
    public updated: Date
  ) {
    // must have same name as in API
    super(
      eService.id,
      eService.name,
      eService.displayName,
      eService.description,
      enabledFrom,
      disabledFrom,
      eService.readOnly,
      eService.isRealEstateService
    );
  }
}
