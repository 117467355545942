import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionOwnedService } from '@data/common/region-owned-service.model';

export interface AddCustomerServiceDialogData {
  newService: RegionOwnedService;
}

/*
 * Used by both CustomerService and RealEstateService tabs.
 */
@Component({
  selector: 'app-add-update-service-dialog',
  templateUrl: './add-update-service-dialog.component.html',
  styleUrls: ['./add-update-service-dialog.component.scss'],
})
export class AddUpdateServiceDialogComponent {
  selectedServiceToName: string;
  selectedServiceToAdd: RegionOwnedService = null;
  isPartOfBasicSortiment = false;
  notReleased = false;
  activeUntilFurtherNotice = true;

  newService: RegionOwnedService;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public service: RegionOwnedService
  ) {
    this.selectedServiceToAdd = service; // use a template input object
    this.activeUntilFurtherNotice =
      this.selectedServiceToAdd.disabledFrom === undefined ||
      this.selectedServiceToAdd.disabledFrom === null;
  }

  onClickCancel(): void {
    this.dialogRef.close(null);
  }

  onClickSave(): void {
    if (this.hasRequiredFields()) {
      this.dialogRef.close(
        new RegionOwnedService(
          this.selectedServiceToAdd,
          this.selectedServiceToAdd.enabledFrom !== undefined
            ? this.selectedServiceToAdd.enabledFrom
            : null,
          this.selectedServiceToAdd.disabledFrom !== undefined
            ? this.selectedServiceToAdd.disabledFrom
            : null,
          null // featureIds, not applicable here
        )
      );
    }
  }

  hasRequiredFields() {
    return this.selectedServiceToAdd != null;
  }

  onChangedActiveUntilFurtherNotice(event: any) {
    this.activeUntilFurtherNotice = event.checked;
    if (this.activeUntilFurtherNotice) {
      this.selectedServiceToAdd.disabledFrom = undefined;
    }
  }
}
