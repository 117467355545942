import { RegionOwnedService } from './region-owned-service.model';
import { Customer } from './customer.model';
import { IIRegion } from '../service/bought-endpoint.service';

export class Region implements IIRegion {
  constructor(
    public id: number,
    public name: string,
    public ownedServices: RegionOwnedService[],
    public customers: Customer[]
  ) {}
}
