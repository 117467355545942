import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  NgModule,
  ApplicationRef,
  LOCALE_ID,
  DoBootstrap,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { DataModule } from './data/data.module';
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import localeSvExtra from '@angular/common/locales/extra/sv';
import { registerLocaleData } from '@angular/common';
import {
  HsbSharedComponentsModule,
  NavigationHttpService,
} from '@hsbweb/hsb-shared-components-module';
import { take } from 'rxjs/operators';
import {} from '@hsbweb/hsb-shared-services-module';
import { HsbSharedUtilsModule } from '@hsbweb/hsb-shared-utils-module';
import {
  EnvironmentSettingsService,
  EnvironmentSettingsHttpService,
  HsbSharedCoreModule,
} from '@hsbweb/hsb-shared-core-module';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeSv, 'sv', localeSvExtra);

export function httpLoaderFactory(
  http: HttpClient,
  settingsService: EnvironmentSettingsService
) {
  // Use this while testing with mock translate and local "sv.json" file
  // return new TranslateHttpLoader(http);

  // Use this in production to load the json translation file from the TextHandler microservice
  return new TranslateHttpLoader(
    http,
    `${settingsService.settings.apiGateway}/texthandler/Text/BoughtServices/`,
    ''
  );
}

export function appInit(
  settingsHttpService: EnvironmentSettingsHttpService,
  navigationHttpService: NavigationHttpService,
  env: EnvironmentSettingsService
) {
  return () =>
    settingsHttpService.initializeApp().then(() => {
      navigationHttpService.initializeApp(
        env.settings.environment,
        env.featureFlags
      );
    });
}

export function dateLocalizeLoader(translateService: TranslateService) {
  return translateService.currentLang || 'sv';
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, EnvironmentSettingsService],
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    HsbSharedCoreModule.forRoot({
      appInsightCloudRole: 'BoughtServices.Web',
      checkServiceIsActive: false,
    }),
    DataModule,
    HsbSharedUtilsModule,
    HsbSharedComponentsModule,
  ],
  providers: [
    {
      /*
              APP_INITIALIZER
              Angular special token for app initialization
              The user factory function will run before the rest of the application starts,
              which will  create the EnvironmentSettingsHttpService and call its function
              initializeApp() that reads the /assets/environment-settings.json
              into our Environment settings.
            */
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [
        EnvironmentSettingsHttpService,
        NavigationHttpService,
        EnvironmentSettingsService,
      ],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: dateLocalizeLoader,
    },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private translate: TranslateService,
    private handler: HttpClient,
    private envSettingsService: EnvironmentSettingsService
  ) {}
  ngDoBootstrap(appRef: ApplicationRef) {
    this.translate.currentLoader = httpLoaderFactory(
      this.handler,
      this.envSettingsService
    );
    this.translate
      .reloadLang('sv')
      .pipe(take(1))
      .subscribe(() => {
        dateLocalizeLoader(this.translate);
        appRef.bootstrap(AppComponent);
      });
  }
}
