import { NgModule } from '@angular/core';
import { API_BASE_URL } from './service/bought-endpoint.service';
import { BoughtService } from './service/bought.service';
import { EnvironmentSettingsService } from '@hsbweb/hsb-shared-core-module';
import { CommonModule } from '@angular/common';

const services = [
  {
    provide: API_BASE_URL,
    useFactory: getApiUrl,
    deps: [EnvironmentSettingsService],
    multi: true,
  },
  BoughtService,
];

export function getApiUrl(settingsService: EnvironmentSettingsService) {
  return `${settingsService.settings.apiGateway}/boughtservices`;
}
@NgModule({
  declarations: [],
  providers: services,
  imports: [CommonModule],
})
export class DataModule {}
