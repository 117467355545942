import { EService } from './eservice.model';

// This is an EService but with additional attribute(s).

export class CustomerOwnedService extends EService {

    constructor(
        eService: EService, // use settings from template object
        enabledFrom: Date, // a region has its own values in the enabledFrom/disabledFrom fields
        disabledFrom: Date,
        public updated: Date) {
        super(eService.id, eService.name, eService.displayName, eService.description,
            enabledFrom, disabledFrom,
            eService.readOnly, eService.isRealEstateService);
    }

}
