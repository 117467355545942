import { RealEstateOwnedService } from './realestate-owned-service.model';

import { IIRealEstate } from '@data/service/bought-endpoint.service';

export class RealEstate implements IIRealEstate {
  ownedServices: RealEstateOwnedService[];

  constructor(
    public id: number,
    public name: string,
    public identityNumber: number
  ) {}

  // Update with latest values - only update the fields of interest
  updateOwnedService(updatedService: RealEstateOwnedService) {
    for (const service of this.ownedServices) {
      if (updatedService.id === service.id) {
        service.enabledFrom = updatedService.enabledFrom;
        service.disabledFrom = updatedService.disabledFrom;
        service.updated = new Date();
      }
    }
  }
}
