import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './angular-material.module';
import { FormsModule } from '@angular/forms';
import { RegionSelectorComponent } from './component/region-selector/region-selector.component';
import { HsbSharedComponentsModule } from '@hsbweb/hsb-shared-components-module';
import { AddUpdateServiceDialogComponent } from './component/add-update-service-dialog/add-update-service-dialog.component';

@NgModule({
  declarations: [RegionSelectorComponent, AddUpdateServiceDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    HsbSharedComponentsModule,
  ],
  exports: [
    MaterialModule,
    TranslateModule,
    FormsModule,
    RegionSelectorComponent,
    HsbSharedComponentsModule,
  ],
})
export class SharedModule {}
