import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Region } from '@data/common/region.model';
import { TranslateService } from '@ngx-translate/core';

/*
 * Dropdown menu for selecting a region.
 */
@Component({
  selector: 'app-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss'],
})
export class RegionSelectorComponent {
  @Input() regions: Region[] = [];
  @Output() eventRegionSelected = new EventEmitter<Region>();

  constructor(private translateService: TranslateService) {}


  getDefaultText() {
    return this.translateService.instant('COMMON.LBL-CHOOSE-REGION');
  }

  onChangeRegion(regId: string) {
    const region = this.regions.find((r) => r.id === +regId); // note: need the "+" as implicit number conversion!
    if (region !== undefined) {
      this.eventRegionSelected.emit(region);
    }
  }
}
